<template>
  <v-container>
    <v-card>
      <v-card-title>
        Actividad Reciente
      </v-card-title>
          <v-divider/>

      <v-card-title>
        
          <v-spacer/>
          <v-btn-toggle v-model="selectedRange" dense mandatory >
          <v-btn value="1M" large>1M</v-btn>
          <v-btn value="3M" large>3M</v-btn>
          <v-btn value="6M" large>6M</v-btn>
          <v-btn value="TODO" large>TODO</v-btn>
        </v-btn-toggle>
                  <v-spacer/>


      </v-card-title>
      <v-card-text>
         <v-row>


      <!-- Chart -->
      <v-col cols="12">
        <v-card id="skelet" class="glass overflow-hidden" height="250" v-if="!neverAssisted">
        <apexchart height="250px" v-if="!loading" type="bar" :options="chartOptions" :series="series"></apexchart>
        <v-skeleton-loader v-else  type="image"></v-skeleton-loader>
        </v-card>


         <v-img v-if="!loading && neverAssisted"
        src="@/assets/alert.svg"
        height="200px"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)"
      >
       
      </v-img>
      <v-alert v-if="neverAssisted" type="info" text>
        Registra tu primera asistencia para ver estadísticas.
      </v-alert>
      </v-col>

      <!-- Asistencias Counts -->
      <v-col cols="12">
        <v-card class="glass">
          <v-card-title>
            Estadísticas de asistencias
          </v-card-title>

          <v-divider/>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="4">
                <div>
                  <p class="mb-2">
                    <strong>Este mes</strong>
                  </p>

                  <p class="headline  mb-1">
                   <strong> {{ counts.thisMonth }}</strong>
                  </p>

                  <p>
                    asistencias
                  </p>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <p class="mb-2">
                    <strong>Este año</strong>
                  </p>

                  <p class="headline mb-1">
                    <strong>{{ counts.thisYear }}</strong>
                  </p>

                  <p>
                    asistencias
                  </p>
                </div>

                
                
                
              </v-col>
              <v-col cols="4">
                <div>
                  <p class="mb-2">
                    <strong>Total</strong>
                  </p>

                  <p class="headline  mb-1">
                    <strong>{{ counts.total }}</strong>
                  </p>

                  <p>
                    asistencias
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>


      <!-- Asistencias Calendar -->
      <v-col cols="12">
        <v-card class="glass">
          <v-card-title>
            Calendario de asistencias
          </v-card-title>

          <v-divider/>
          <v-card-text>
              <v-date-picker 
       
        :events="events"
        event-color="green lighten-1"
      ></v-date-picker>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

      </v-card-text>
    </v-card>

   
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import 'moment/locale/es'; // Import Spanish locale
import { getFirestore, collection, getDocs, orderBy } from 'firebase/firestore';

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      events: [],
      loading: true,
      selectedRange: '1M',
      chartOptions: {
        chart: { id: 'asistencias-chart',
          height: 350,
           toolbar: {
            show: false,
          },
         },
        xaxis: {
          categories: [],
        },
        yaxis: {
         
          labels: {
            formatter: function (val) {
              return Math.floor(val);
            },
          },
        },

         theme: {
             mode: this.$store.state.isDark ? 'dark' : 'light',
            palette: 'palette1',
        },
      },
      series: [
        {
          name: 'Asistencias',
          data: [],
        },
      ],
      counts: {
        thisMonth: 0,
        thisYear: 0,
        total: 0,
      },
      allAssistances: [], // All assistances fetched from Firestore,
      neverAssisted: false,
    };
  },
  watch: {
    selectedRange() {
      this.loading = true;

      this.$nextTick(() => {
        this.updateChart();

      });
    },
  },
  mounted() {
    moment.locale('es'); // Set moment locale to Spanish
    this.fetchAssistances();
  },
  methods: {
    getEventColor(event) {
      return event.color || '#1976D2';
    },
    async fetchAssistances() {
      const db = getFirestore();
      const userid = this.$store.state.Auth.token.claims.user_id;
      const workoutsRef = collection(db, `users/${userid}/workouts`);

      // Fetch all workouts
      const querySnapshot = await getDocs(workoutsRef);
      const assistances = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.dates.forEach((timestamp) => {
          const dateObj = moment(timestamp,"YYYY-MM-DD HH:mm:ss");
          assistances.push(dateObj);

          // Add event to calendar
          this.events.push(dateObj.format('YYYY-MM-DD'));

        });
      });

      if (assistances.length === 0) {
         this.neverAssisted = true;
      }

      this.allAssistances = assistances;

      this.updateCounts();
      this.updateChart();
    },
    updateChart() {

      this.loading = true;
      let filteredAssistances = [];

      switch (this.selectedRange) {
        case '1M':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(1, 'months').startOf('day'))
          );
          break;
        case '3M':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(3, 'months').startOf('day'))
          );
          break;
        case '6M':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(6, 'months').startOf('day'))
          );
          break;
        case '1A':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(1, 'years').startOf('day'))
          );
          break;
        case 'TODO':
          filteredAssistances = this.allAssistances;
          break;
      }

      if (this.selectedRange === '1M') {
        // Group by weekdays in Spanish
        const weekdays = ['lun', 'mar', 'mié', 'jue', 'vie', 'sáb', 'dom'];
        const data = [0, 0, 0, 0, 0, 0, 0];

        filteredAssistances.forEach((date) => {
          const dayOfWeek = date.isoWeekday(); // 1 (Monday) to 7 (Sunday)
          data[dayOfWeek - 1]++;
        });

        this.chartOptions.xaxis.categories = weekdays;
        this.series = [
          {
            name: 'Asistencias',
            data: data,
          },
        ];
      } else {
        // Group by month
        const monthCounts = {};

        filteredAssistances.forEach((date) => {
          let month = date.locale("es").format('MMM');
          month = month.replace('.', ''); // Remove dot
          if (!monthCounts[month]) {
            monthCounts[month] = 0;
          }
          monthCounts[month]++;
        });

        // Get months in order
        const monthsOrder = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
        const categories = monthsOrder.filter((month) => monthCounts[month]);
        const data = categories.map((month) => monthCounts[month]);

        this.chartOptions.xaxis.categories = categories;
        this.series = [
          {
            name: 'Asistencias',
            data: data,
          },
        ];

      }

      this.loading = false;
    },
    updateCounts() {
      const now = moment();
      this.counts.total = this.allAssistances.length;
      this.counts.thisMonth = this.allAssistances.filter((date) => date.isSame(now, 'month')).length;
      this.counts.thisYear = this.allAssistances.filter((date) => date.isSame(now, 'year')).length;
    },
  },
};
</script>

<style scoped>
.glass{
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
}




</style>
<style >
#skelet .v-skeleton-loader__image{
  height: 250px !important;
}
</style>
